import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { fetchPostalCodes } from '../../redux/Actions';
import { setValue } from '../../utility';
import { fetchGetShopByPinCode } from '../../redux/Actions/checkoutPageActions';
import { DebounceInput } from 'react-debounce-input';
import { icon1,icon2,icon3,icon4,icon5,icon6,icon7,icon8,secimg1,secimg2,secimg3,secimg4,user1,user2,user3,offer1,offer2,offer3 } from "../../assets/img";
import { fetchFaqData } from "../../redux/Actions/policyActions";
import { Accordion } from "react-bootstrap";

// import BookNow from './index';

// const postalCode = [
//     { content: "GU111BH" },
//     { content: "GU111KD" },
//     { content: "GU111MH" },
// ];

function BookNow() {
    const [postalCodeList, setPostalCodeList] = useState([])
    const [postalCodeValue, setPostalCodeValue] = useState("")
    const dispatch = useDispatch<any>();
    const state: any = useSelector(state => state);
    const navigate = useNavigate();
    // const base_url = "https://revamp.dreamguystech.com"


    // const fetchData = async () => {
    //     const base_url = "https://revamp.dreamguystech.com";
    //     try {
    //         const bodyFormData = new FormData();
    //         bodyFormData.append('keyword', 'Gu11');
    //         // res.header('Access-Control-Allow-Methods', 'GET, POST');
    //         const response = await axios({
    //             method: "post",
    //             url: `${base_url}/api/get_postal_codes`,
    //             data: bodyFormData,
    //             headers: { "Accept": "application/json", 'Access-Control-Allow-Methods': 'GET, POST' },
    //         })
    //         // const response = await axios.post(`${base_url}/api/get_postal_codes`,{keyword:"Gu11"});
    //         // const result = await response.then(response => response);

    //         if (response) {
    //             setPostalCodeList(response.data.data[0])
    //             
    //         }
    //     } catch (error) {
    //         
    //     }
    // }


    useEffect(() => {
        if (state) {
            setPostalCodeList(state.postalCodeList);
            // setValue("postalCode",state.postalCodeList[0]?.postcode)
        }

    }, [state])

    // useEffect(() => {
    //     // fetchData();
    //     dispatch(fetchPostalCodes())
    //     // dispatch(fetchPostalCodes());
    // }, [])

    const handleChange = (e: any) => {
        const result = e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
        // setPostalCodeList(e.target.value);
        const { value } = e.target;
        const updateValue = value.replace(/\s/g,'')
        if (value.length > 0) {
            dispatch(fetchPostalCodes(updateValue));
        }
        setPostalCodeValue(updateValue);

    }
    const onSearch = (searchTerm: any) => {
        // setPostalCodeList(searchTerm);
        setPostalCodeValue(searchTerm);
        setValue("postalCode",searchTerm.toUpperCase())
        // dispatch(fetchPostalCodes(searchTerm));


    };
    const handleBooknow = async () => {
        const isValid = state.postalCodeList.some((item:any) => item.postcode === postalCodeValue.toUpperCase());
        setValue("postalCode",postalCodeValue.toUpperCase())
        const results = await dispatch(fetchGetShopByPinCode(JSON.stringify(postalCodeValue)));
        
        if (!results) {
            navigate("/areaNotCovered");
            return;
        }
        if(isValid){
            navigate("/productLists");
        }else{
            navigate("/areaNotCovered");
        }
        
    }
    const faqData: any = useSelector<any>((state) => state?.faq);
  useEffect(() => {
    dispatch(fetchFaqData());
  }, []);
    return (
        <>
        <div className="home_single_search">

            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-7">
                        <div className='banner-search-wrap'>
                            <h1>Laundry & dry cleaning with 24h delivery in Wimbledon</h1>                            
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-5">
                        <div className="postcodeform">
                            {/* <input type="text" id="postcode-input" value={postalCodeValue} name="pincode" placeholder="Enter Zipcode" className="ui-autocomplete-input" onChange={handleChange} autoComplete="off" /> */}
                            <DebounceInput debounceTimeout={500} value={postalCodeValue} placeholder="Enter Zipcode" className="ui-autocomplete-input" onChange={handleChange} autoComplete="off" />

                            <button disabled={postalCodeValue.length === 0} onClick={handleBooknow} className="btn hover-btn" id="book_now_btn" type="button"><i className="fas fa-search me-2"></i>Book Now</button>
                            <div className="dropdown">
                                {postalCodeList
                                    .filter((item: any) => {
                                        const searchTerm = postalCodeValue.toLowerCase();
                                        const mainValue = item.postcode.toLowerCase();

                                        return (
                                            searchTerm &&
                                            mainValue.startsWith(searchTerm) &&
                                            mainValue !== searchTerm
                                        );
                                    })
                                    .slice(0, 10)
                                    .map((item: any) => (
                                        <div
                                            onClick={() => onSearch(item.postcode)}
                                            className="dropdown-row"
                                            key={item.postcode}
                                        >
                                            <i className="fas fa-map-marker-alt"></i>{item.postcode}
                                        </div>
                                    ))}
                            </div>
                            {/* <ul id="ui-id-1" tabIndex={0} className="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front" style={{ top: "477.688px", left: "104.5px", width: "461.266px", display: "none" }}>
                                    <li className="ui-menu-item">
                                        <div id="ui-id-4" tabIndex={-1} className="ui-menu-item-wrapper">GU111BH</div>
                                    </li>
                                </ul> */}



                            {/* <form method="POST" action="https://revamp.dreamguystech.com/selection" accept-charset="UTF-8" id="postForm">
                                <input name="_token" type="hidden" value="weQWHBzpwFRfH1sjw4hbF0vHiMbe3AsgTSQB2vnP" />
                                <input name="postalCode" type="hidden" value="" /> */}
                                {/* <input className="btn btn-default hidden" type="submit" value="Submit" /> */}
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <section className='section-info'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='section-title'>
                            <h2>Choose when and where you wish us to<br/> collect & deliver your laundry</h2>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='section-info-list'>
                            <ul className='list-info'>
                                <li>
                                    <img src={icon1} alt=''/>
                                    <p>24h turnaround time</p>
                                </li>
                                <li>
                                    <img src={icon2} alt=''/>
                                    <p>Free collection and delivery</p>
                                </li>
                                <li>
                                    <img src={icon3} alt=''/>
                                    <p>Get in Touch</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='sec-pve'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='sec-overlay-container'>
                            <img src={secimg1} alt=''/>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='sec-info-grid'>
                            <div className='sub-head'>ABOUT US</div>
                            <h2 className='sec-header'>Professional, Eco-FriendlyCleaning</h2>
                            <p>At Pure Elegant, our commitment to you is unwavering. We
                                strive to provide the highest quality service with meticulous
                                attention to detail, ensuring that your garments receive the
                                care they deserve. Our aim is to deliver exceptional value for
                                your money, offering competitive pricing without
                                compromising on the quality of our work.
                            </p>
                            <a href='' className='sec-link'>See how it works</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='sec-pve'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='sec-info-grid'>
                            <div className='sub-head'>TURNAROUND WITHIN 24TH</div>
                            <h2 className='sec-header'>Need dry cleaning services on short notice.</h2>
                            <ul className='list-info'>
                                <li>
                                    <img src={icon4} alt=''/>
                                    <p>Schedule a pickup today</p>
                                </li>
                                <li>
                                    <img src={icon1} alt=''/>
                                    <p>Get your laundry within 24h</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='sec-overlay-container'>
                            <img src={secimg2} alt=''/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='sec-pve'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='sec-overlay-container'>
                            <img src={secimg3} alt=''/>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='sec-info-grid'>
                            <div className='sub-head'>COMMERCIAL</div>
                            <h2 className='sec-header'>Commercial Dry Cleaning & Laundry Service</h2>
                            <p>At Pure Elegant, we take pride in offering premier corporate dry
                                cleaning and laundry services that cater to your convenience
                                and needs. Our specialized service operates from various
                                locations, including residential buildings, workplaces, hotels,
                                and office buildings, ensuring easy access for local inhabitants
                                and employees.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='sec-testimonial'>
            <div className='text-center sec-testimonial-header'>
                <div className='sub-head'>COMMERCIAL</div>
                <h2 className='sec-header'>Commercial Dry Cleaning & Laundry Service</h2>
                <p>Rated Excellent <span className='sec-stars'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></span> by Users</p>
            </div>

            <div className='container'>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='testimonial-grid'>
                            <p>“I rarely leave reviews, however this
                                time I thought need to leave one. Been
                                using various Dry cleaners across
                                London but this Service was beyond
                                excellent at competitive price!
                                Flexibility of home pick up and drop off.
                                Excellent dry cleaners! Recommend!”
                            </p>
                            <div className='testimonial-user-info'>
                                <img src={user1} alt=''/>
                                <div className='testimonial-user'>
                                    <h5>lgor Griskin</h5>
                                    <span className='sec-stars'>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='testimonial-grid'>
                            <p>“It is the 2nd time I have used these
                                guys and they are extremely
                                professional and kind. Would defo
                                recommend them!”
                            </p>
                            <div className='testimonial-user-info'>
                                <img src={user2} alt=''/>
                                <div className='testimonial-user'>
                                    <h5>lgor Griskin</h5>
                                    <span className='sec-stars'>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='testimonial-grid'>
                            <p>“Outstanding service - had my suit dry
                                cleaned and delivered , excellent
                                customer service , highly
                                recommended”
                            </p>
                            <div className='testimonial-user-info'>
                                <img src={user3} alt=''/>
                                <div className='testimonial-user'>
                                    <h5>lgor Griskin</h5>
                                    <span className='sec-stars'>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='section-schedule'>
            <h2>Book now. Smile away. Everyday.</h2>
            <button className='btn btn-style1'>Schedule a Pickup</button>
        </section>
        <section className='section-ben'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='sec-info-grid'>
                            <h2 className='sec-header'>Your Laundry and Dry cleaning solution just a click away</h2>
                            <ul className='list-info'>
                                <li>
                                    <img src={icon5} alt=''/>
                                    <p>Cashless Payments</p>
                                </li>
                                <li>
                                    <img src={icon6} alt=''/>
                                    <p>Contactless collection and delivery</p>
                                </li>
                                <li>
                                    <img src={icon7} alt=''/>
                                    <p>Real-time order updates</p>
                                </li>
                                <li>
                                    <img src={icon8} alt=''/>
                                    <p>Dedicated 24/7 support</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='sec-overlay-container'>
                            <img src={secimg4} alt=''/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='sectionfaq'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h4>DO YOU HAVE QUESTIONS?</h4>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-md-8'>
                        {faqData?.map((item: any) => {
                            return (
                            <Accordion
                                defaultActiveKey={["1"]}
                                className="faq-grp"
                            >
                                <Accordion.Item eventKey={JSON.stringify(item?.id)}>
                                <Accordion.Header>{item?.question}</Accordion.Header>
                                <Accordion.Body>{item?.answer}</Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
        <section className='section-offer'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h4 className='secoffer-hdr'>Special Offers</h4>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='offer-grid'>
                            <div className='offer-img'>
                                <img src={offer1} alt='' />
                                <a href=''><i className="fas fa-caret-right me-1"></i>Send a gift card</a>
                            </div>
                            <p>Satisfied with our service? Send a gift card to someone you love.</p>
                        </div>                        
                    </div>
                    <div className='col-md-4'>
                        <div className='offer-grid'>
                            <div className='offer-img'>
                                <img src={offer2} alt='' />
                                <a href=''><i className="fas fa-caret-right me-1"></i>Refer a friend and earn</a>
                            </div>
                            <p>Want to get a discount code? Refer friends and earn.</p>
                        </div>                        
                    </div>
                    <div className='col-md-4'>
                        <div className='offer-grid'>
                            <div className='offer-img'>
                                <img src={offer3} alt='' />
                                <a href=''><i className="fas fa-caret-right me-1"></i>Refer a friend and earn</a>
                            </div>
                            <p>Want to get a discount code? Refer friends and earn.</p>
                        </div>                        
                    </div>
                </div>
            </div>
        </section>
        <section className='section-area'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='area-desc'>
                            <h4>Pure Elegant in London</h4>
                            <p>Is your laundry piling up? Book your London Pure Elegant service today for fresh clothing tomorrow. Pure Elegant is a convenient,
                            online, on-demand laundry and dry cleaning service, operational across London. With a 24-hour turnaround time and same-day
                            collection options available, you can book your Pure Elegant order today and have clean clothing ready to wear tomorrow. Doing
                            laundry has never been easier.</p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='area-desc'>
                            <h4>London delivery and pickup</h4>
                            <p>Booking your Pure Elegant order is simple. Head to the Pure Elegant website, or download the free Pure Elegant app, and pick a time
                            for us to collect your laundry that suits you. We’ll pick up your items, deliver them to our facility, and redeliver them back to you at a
                            time that’s best for you. It’s quick, easy, and, with our free collection and delivery service, affordable. Your laundry could be clean with
                            just the touch of a button.</p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='area-desc'>
                            <h4>Free Local Collection and Delivery</h4>
                            <ul>
                                <li>
                                    <a href=''><i className="fas fa-chevron-right me-2"></i>Balham</a>
                                </li>
                                <li>
                                    <a href=''><i className="fas fa-chevron-right me-2"></i>Norbury</a>
                                </li>
                                <li>
                                    <a href=''><i className="fas fa-chevron-right me-2"></i>Fulham</a>
                                </li>
                                <li>
                                    <a href=''><i className="fas fa-chevron-right me-2"></i>Clapham</a>
                                </li>
                                <li>
                                    <a href=''><i className="fas fa-chevron-right me-2"></i>Tooting</a>
                                </li>
                                <li>
                                    <a href=''><i className="fas fa-chevron-right me-2"></i>Wimbledon</a>
                                </li>
                                <li>
                                    <a href=''><i className="fas fa-chevron-right me-2"></i>Battersea</a>
                                </li>
                                <li>
                                    <a href=''><i className="fas fa-chevron-right me-2"></i>Brixton</a>
                                </li>
                                <li>
                                    <a href=''><i className="fas fa-chevron-right me-2"></i>Streatham</a>
                                </li>
                                <li>
                                    <a href=''><i className="fas fa-chevron-right me-2"></i>Wandsworth</a>
                                </li>
                                <li>
                                    <a href=''><i className="fas fa-chevron-right me-2"></i>Chelsea</a>
                                </li>
                                <li>
                                    <a href=''><i className="fas fa-chevron-right me-2"></i>West Norwood</a>
                                </li>
                                <li>
                                    <a href=''><i className="fas fa-chevron-right me-2"></i>Mitcham</a>
                                </li>
                                <li>
                                    <a href=''><i className="fas fa-chevron-right me-2"></i>Putney</a>
                                </li>
                                <li>
                                    <a href=''><i className="fas fa-chevron-right me-2"></i>Dulwich</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default BookNow;