import React , { useEffect,useState } from 'react'
import axios from 'axios'
import { Forgotpwd } from '../../assets/img'
const base_url = process.env.REACT_APP_BACKEND_URL;
const client_base_url=process.env.REACT_APP_BASE_URL;


function ForgotPassword() {

    const [emailValue, setEmailValue] = useState("")
    const [loginResult, setLoginResult] = useState< any | null>({})
    const [genError, setGenError] = useState("") 
    const [success, setSuccess] = useState(false) 
    const handleChange = (e: any) => {    
        setEmailValue(e.target.value);
        }

    const handleSubmit = (event: any) => {
        event.preventDefault()  
      
        const headers = {'Accept': 'application/json' }
          
            axios.post(`${base_url}/api/forgot_password`,{ email:emailValue },{
            headers: headers
    
      }).then(e=>{setLoginResult({result:e.data})
        if(e.data.Response.response_code=="1" )
        {
         // window.location.href=`${client_base_url}/`
         setGenError(e.data.Response.response_message);
         setSuccess(true)
        }
        else
        {
          setGenError(e.data.Response.response_message);
          event.preventDefault()
          setSuccess(false)
          return false
        }
      })
       
    }


    return (

        <div className="section-signin">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 col-centered">
                        <div className="signin">
                            <div className="signin-header forgot-pwd">
                                <img src={Forgotpwd} className="img-fluid" alt="" />
                                <h1>Forgot password?</h1>
                            </div>
                            <div className="signin-conent">
                                <form className="form-signin" name="reset" method="POST" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>Your Email <span className="required">*</span></label>
                                        <input type="email" name="email" value={emailValue} className="form-control signin-form" required  onChange={handleChange}/>
                                        <input type="hidden" name="_token" value="UVC2hXCgh7rSyJaKMnteV7KQxyvCX8pIqQU0tmqQ" />
                                    </div>
                                    <div className="form-group p-fd-inst">
                                        <p>We will send you the instructions on how to change your password to this email.</p>
                                       
                                        <div className="name_error">{genError}</div>                                        
                                    </div>
                                    <div className="form-group text-center">
                                        <input type="hidden" value=" " name="redirect_to" />
                                        <button disabled={success} type="submit" className="btn btn-primary account-btn">Send Password Reset Link</button>
                                    </div>
                                </form>
                            </div>
                            <div className="text-center register-link">
                                <span><a href="/login"><i className="fa fa-angle-double-left" aria-hidden="true"></i>Back to Login</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    

    )
}

export default ForgotPassword;
