import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./../footer/footer.css";
import { NavLink } from 'react-router-dom';
import { appstore,googleplay,logo } from '../../assets/img'
import { useDispatch, useSelector } from "react-redux";

export const SET_SETTINGS = "SET_SETTINGS";

export const setSettingsAll = (payload: any) => ({
  type: SET_SETTINGS,
  payload
});

function Footer() {
  const navigate = useNavigate();
  const Url = window.location.host
  const blogUrl = `https://blog.${Url}`

  const base_url = process.env.REACT_APP_BACKEND_URL;
  // const base_url = "http://www.bestatlaundry.test";

  const [settings, setSettings] = useState<any>({});

  const appStore = Object.keys(settings).length > 0 && settings.WebmasterSettings.app_store_link
  const playStore = Object.keys(settings).length > 0 && settings.WebmasterSettings.play_store_link
  const facebook = Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link1
  const twitter = Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link2
  const instagram = Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link6
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (Object.keys(settings).length === 0) {
      axios({
        method: "get",
        url: `${base_url}/api/settings`,
        headers: {
          "Accept": "application/json",
          'Access-Control-Allow-Methods': 'GET, POST',
        },
      }).then((response) => {
        if (response.status != 401) {
          setSettings(response.data.data);
          dispatch(setSettingsAll(response.data.data))
        }
      }).catch(function (error) {
        
      })
    }
  })

  var dateObj = new Date();
  var year = dateObj.getUTCFullYear();

  return (
      <footer className="footer">
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='footer-logo'>
                  <img src={logo} alt=''/>
              </div>
            </div>
            <div className='col-md-4'>
              <div className="footer-sec">
                  <h3>Quick Connect</h3>
                  {appStore == "" && playStore == "" ? 
                    "coming Soon"
                  :                      
                  <ul>
                    <li><a target={appStore == ""? '' : "_blank"} href={appStore == ""? 'javascript:;' : appStore} className="hvr-float-shadow imggbg"><img src={appstore} className="img img-fluid" alt="appstore" /></a></li>
                    <li><a target={playStore == ""? '' : "_blank"} href={playStore == ""? 'javascript:;' : playStore} className="hvr-float-shadow imggbg2"><img src={googleplay} className="img img-fluid" alt="appstore" /></a></li>
                  </ul>
                  }                    
              </div>
            </div>
            <div className='col-md-4'>
              <div className="footer-sec">
                  <h3>Follow us</h3>
                  <ul className="footersecsocial">
                    {Object.keys(settings).length > 0 && 
                    settings.WebsiteSettings.social_link1 !=='' &&
                      <li><a target="_blank" href={facebook}><i className="fab fa-facebook-f" aria-hidden="true"></i></a></li>
                    }
                                          {Object.keys(settings).length > 0 && 
                    settings.WebsiteSettings.social_link6 !=='' &&
                      <li><a target="_blank" href={instagram}><i className="fab fa-instagram" aria-hidden="true"></i></a></li>
                                          }
                                          {Object.keys(settings).length > 0 && 
                    settings.WebsiteSettings.social_link2 !=='' &&
                      <li><a target="_blank" href={twitter}><i className="fab fa-twitter" aria-hidden="true"></i></a></li>
                                          }
                  </ul>
              </div>
            </div>
          </div>
          <div className='row'>
             <p className="footer-resv">
                All rights reserved. © {Object.keys(settings).length > 0 && settings?.WebsiteSettings?.site_title_en} {year}. By visiting this page you agree to our privacy policy and terms and conditions. Powered by | <a target='_blank' href='https://bestatservices.com/' >www.bestatservices.com</a>               
            </p>
          </div>
        </div>
      </footer>
  )
}

export default Footer;