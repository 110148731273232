import axios from "axios";
import { toast } from "react-toastify";

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        
        // toast(response.data.Response.response_message);
        if (error.response.status != undefined && error.response.status === 401) {
          const isCompleteOrder = localStorage.getItem("isCompleteOrder");
            if(localStorage.getItem("isCompleteOrder") !== '1'){
            toast.error(error.response.data.Response.response_message+" "+error?.message);
          }
            // localStorage.clear();
            // navigate("/login")
            // window.location.href = "/sessionTimeOut";
            // window.location.href = "/login";
            setTimeout(() => {
              if(isCompleteOrder){
                const order_id = localStorage.getItem("order_id");
                localStorage.clear();
                localStorage.setItem("isCompleteOrder", '1');
                localStorage.setItem("order_id", order_id);
              }else {
                localStorage.clear();
              }
                window.location.href = "/login";
            }, 3000);
        }
      if (error.response.status === 403) {
        // localStorage.clear();
        window.location.href = "/connexion";
      }
  
      // reject with error if response status is not 403
      return Promise.reject(error);
    }
  );

  export default axios;
  